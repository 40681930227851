import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs/operators';

import {
	EidRedirectWarning,
	UserEIDIdentitySwitchDirective,
	UserEIDLoginDirective
} from '@cjm/shared/authentication/auth';
import { MetaService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { ACMTargetGroups } from '@cjm/shared/types';
import { BreadcrumbService } from '@cjm/shared/ui/breadcrumb';
import {
	ButtonClasses,
	ButtonComponent,
	FaIconComponent,
	LayoutContainerComponent,
	LinkComponent
} from '@cjm/shared/ui/common';
import { HasRoleDirective } from '@cjm/shared/user';
import { IconListItem, IconListComponent } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';

@Component({
	templateUrl: './start.component.html',
	styleUrls: ['./start.component.scss'],
	standalone: true,
	imports: [
		LayoutContainerComponent,
		FaIconComponent,
		IconListComponent,
		ButtonComponent,
		UserEIDLoginDirective,
		UserEIDIdentitySwitchDirective,
		AsyncPipe,
		TranslateModule,
		LinkComponent,
		HasRoleDirective
	]
})
export class StartPageComponent implements OnInit, OnDestroy {
	@HostBinding('class.p-registration-start') private readonly hasPageClass: boolean = true;

	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly callbackUrl: string = `/${this.i18nService.currentLanguage}/${this.appRoutePaths.Registration}/${this.appRoutePaths.RegistrationRedirectAuthentication}/`;
	public readonly acmTargetGroups: typeof ACMTargetGroups = ACMTargetGroups;
	public readonly authRedirectFVRegistrationWarning: EidRedirectWarning = {
		title: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Notice.AutoLoginTitle),
		text: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Notice.TextRegisterFV),
		confirmButton: {
			title: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Notice.ConfirmLabel),
			text: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Notice.ConfirmLabel)
		},
		cancelButton: {
			title: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Notice.CancelLabel),
			text: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Notice.CancelLabel)
		}
	};

	public associationCharacteristics$: ObservableArray<IconListItem> = this.i18nService
		.getTranslationObservable<string[]>(I18nKeys.Registration.Start.Association.Characteristics)
		.pipe(
			map((items: string[]) =>
				items.map((text: string) => ({
					icon: 'fa-check',
					iconType: 'fa-solid',
					text,
					iconClass: 'white'
				}))
			)
		);
	public foundationCharacteristics$: ObservableArray<IconListItem> = this.i18nService
		.getTranslationObservable<string[]>(I18nKeys.Registration.Start.Foundation.Characteristics)
		.pipe(
			map((items: string[]) =>
				items.map((text: string) => ({
					icon: 'fa-check',
					iconType: 'fa-solid',
					text,
					iconClass: 'white'
				}))
			)
		);

	constructor(
		private readonly i18nService: I18nService,
		private readonly breadcrumbService: BreadcrumbService,
		private readonly metaService: MetaService,
		private readonly router: Router
	) {}

	public ngOnInit(): void {
		this.metaService.updateMetaData({
			title: this.i18nService.getTranslation(this.i18nKeys.PageTitles.VLoket.StartRegistration),
			description: this.i18nService.getTranslation(this.i18nKeys.Registration.Start.Intro),
			pageUrl: this.router.url
		});

		this.breadcrumbService.setBreadcrumbs(
			[
				{
					routePath: [],
					title: this.i18nKeys.PageTitles.VLoket.StartRegistration,
					allowNav: false
				}
			],
			true
		);
	}

	public ngOnDestroy(): void {
		this.breadcrumbService.clearBreadcrumbs();
	}
}
