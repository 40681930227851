<cjm-layout-container class="p-registration-duplicates__intro" layoutType="split-content-left">
	<ng-container main>
		@if (conflictingAssociations() && registrationData()) {
			<cjm-button
				classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }} u-margin-bottom-lg"
				[title]="i18nKeys.Registration.DuplicateCheck.EditCTA.Title | translate"
				[isDisabled]="actionInProgress()"
				[routerLink]="['..', this.appRoutePaths.RegistrationForm]"
			>
				<fa-icon icon="fa-angle-left" /> {{ i18nKeys.Registration.DuplicateCheck.EditCTA.Text | translate }}
			</cjm-button>
		}

		<h1 class="c-vloket-header c-vloket-header--h1">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ i18nKeys.Registration.DuplicateCheck.Title | translate }}
			</span>
		</h1>

		@if (conflictingAssociations() && registrationData()) {
			<!--
			Denis: outerHTML is used to replace the placeholder p-element with the HTML provided by the translation.
			This means that the p-element provided below, will not be rendered in the DOM.
			-->
			<div
				class="c-vloket-text--lg u-margin-bottom-xl"
				[innerHTML]="i18nKeys.Registration.DuplicateCheck.Intro | translate"
			></div>

			<cjm-alert
				[alertMessage]="i18nKeys.Registration.DuplicateCheck.AvoidDuplicatesNotice | translate"
				[alertType]="alertType.Info"
				[isClosable]="false"
			/>
		} @else {
			<cjm-alert
				[alertMessage]="
					i18nKeys.Registration.DuplicateCheck.LostContextNotice
						| translate
						| withRouterLinks
							: [
									{
										dataLinkId: 'start',
										link: [i18nService.currentLanguage, appRoutePaths.Registration]
									}
							  ]
				"
				[alertType]="alertType.Info"
				[isClosable]="false"
			/>
		}
	</ng-container>
</cjm-layout-container>

@if (conflictingAssociations(); as conflictingAssociations) {
	<cjm-layout-container layoutType="full-width" preventSpacing="top">
		<p class="p-registration-duplicates__duplicate-count" main>
			<strong>
				<span class="c-vloket-text-marking c-vloket-text-marking--underline">{{
					conflictingAssociations.length
				}}</span>
			</strong>
			{{
				(conflictingAssociations.length === 1
					? i18nKeys.Registration.DuplicateCheck.ResultInidicator
					: i18nKeys.Registration.DuplicateCheck.ResultsInidicator
				) | translate
			}}
		</p>
	</cjm-layout-container>
	<cjm-layout-container layoutType="full-width" preventSpacing="top">
		<ng-container main>
			<div class="p-registration-duplicates__duplicate-overview">
				@for (association of conflictingAssociations; track association.associationNumber) {
					<vloket-association-card
						classes="u-margin-bottom-lg"
						target="_blank"
						[association]="association"
						[withLink]="true"
						[link]="[
							'/',
							i18nService.currentLanguage,
							appRoutePaths.Associations,
							association.associationNumber
						]"
					>
						<div class="p-registration-duplicates__duplicate-overview__actions">
							@if (
								association.associationType.code === associationCode.FV ||
								association.associationType.code === associationCode.AFD
							) {
								<cjm-link
									[title]="
										i18nKeys.Registration.DuplicateCheck.RequestMaintainershipCTA.Title | translate
									"
									[classes]="buttonClasses.LinkButtonOutlineSolid"
									[to]="[
										'/',
										i18nService.currentLanguage,
										appRoutePaths.Representative,
										appRoutePaths.Requests,
										association.associationNumber
									]"
									[queryParams]="{
										email: registrationData().representative.representativeEmailField,
										source: 'DUPLICATES'
									}"
									>{{
										i18nKeys.Registration.DuplicateCheck.RequestMaintainershipCTA.Text | translate
									}}</cjm-link
								>
							} @else {
								<cjm-link
									[title]="
										i18nKeys.Registration.DuplicateCheck.RequestMaintainershipCTA.Title | translate
									"
									[classes]="buttonClasses.LinkButtonOutlineSolid"
									[to]="[
										'/',
										i18nService.currentLanguage,
										appRoutePaths.Redirects,
										appRoutePaths.Stop
									]"
									[queryParams]="{
										cause: stopCodes.REQUEST,
										case: stopCases.KBO,
										data: { associationName: association.names.full } | json
									}"
									>{{
										i18nKeys.Registration.DuplicateCheck.RequestMaintainershipCTA.Text | translate
									}}</cjm-link
								>
							}
							<cjm-info
								tooltipPosition="top"
								[description]="i18nKeys.Registration.DuplicateCheck.ToolTip | translate"
							/>
						</div>
					</vloket-association-card>
				}
				@if (confirmationToken(); as token) {
					@if (registrationData(); as data) {
						<v-loket-create-as-new
							[registrationData]="data"
							[mainActivities]="mainActivities()"
							[editLink]="[
								'/',
								i18nService.currentLanguage,
								appRoutePaths.Registration,
								appRoutePaths.RegistrationForm
							]"
						>
							<cjm-button
								[classes]="buttonClasses.LinkButtonOutlineSolid"
								[title]="i18nKeys.Registration.DuplicateCheck.CreateCTA.Title | translate"
								(handleClick)="createAsNew(data, token)"
							>
								{{ i18nKeys.Registration.DuplicateCheck.CreateCTA.Text | translate }}
							</cjm-button>
						</v-loket-create-as-new>
					}
				}
			</div>
			<cjm-button
				classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }} u-margin-bottom-lg"
				[title]="i18nKeys.Registration.DuplicateCheck.EditCTA.Title | translate"
				[isDisabled]="actionInProgress()"
				[routerLink]="['..', this.appRoutePaths.RegistrationForm]"
			>
				<fa-icon icon="fa-angle-left" /> {{ i18nKeys.Registration.DuplicateCheck.EditCTA.Text | translate }}
			</cjm-button>
		</ng-container>
	</cjm-layout-container>
}
