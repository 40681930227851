import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AddressEntity } from '@cjm/shared/types';
import { FaIconComponent, LinkComponent, TagComponent } from '@cjm/shared/ui/common';
import { OneLineAddressPipe } from '@cjm/shared/utils';
import { IMainActivity } from '@cjm/v-loket/repositories';
import {
	AssociationActivityEntity,
	AssociationDetailActivitiesComponent,
	ILocationForm,
	IRepresentativeForm
} from '@cjm/v-loket/shared';

import { IRegisterFormDataEntity } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'v-loket-create-as-new',
	templateUrl: 'create-as-new.component.html',
	styleUrl: 'create-as-new.component.scss',
	host: {
		'[class.c-create-as-new]': 'true'
	},
	imports: [
		OneLineAddressPipe,
		TagComponent,
		AssociationDetailActivitiesComponent,
		FaIconComponent,
		LinkComponent,
		TranslateModule
	],
	standalone: true
})
export class CreateAsNewComponent {
	@Input({ required: true })
	public set registrationData(data: IRegisterFormDataEntity) {
		this.existingData = data;

		this.getExistingData();
	}
	@Input({ required: true }) public set mainActivities(activities: IMainActivity[]) {
		this.availableActivities = activities;

		this.getExistingData();
	}
	@Input({ required: true }) public editLink: string[];

	private existingData: IRegisterFormDataEntity = null;
	private availableActivities: IMainActivity[] = [];

	public name: string = '';
	public address: AddressEntity = null;
	public representative: IRepresentativeForm = null;
	public activities: AssociationActivityEntity[] = [];
	public amountOfInvites: number = 3;

	public i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * getExistingData
	 *
	 * The getExistingData method will extract the data from the registration data and set the component properties.
	 *
	 * @private
	 */
	private getExistingData(): void {
		if (!this.existingData || this.availableActivities?.length === 0) {
			return;
		}

		this.name = this.existingData.basicInfo.associationName;
		this.address = this.convertAddress(this.existingData.basicInfo.associationAddress);
		this.representative = this.existingData.representative;
		this.activities = this.availableActivities.filter(
			(activity: IMainActivity) => this.existingData.basicInfo.mainActivities[activity.id]
		);
		this.amountOfInvites = Object.values(this.existingData.invites).length;
	}

	private convertAddress(associationAddress: ILocationForm): AddressEntity {
		return {
			street: associationAddress.street,
			number: associationAddress.number,
			zipcode: associationAddress.zipcode,
			municipality: associationAddress.locality,
			box: associationAddress.box,
			country: associationAddress.country
		};
	}
}
