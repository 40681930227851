<p class="u-margin-bottom">
	{{ i18nKeys.Registration.CreateAsNew.RecapDisclaimer | translate }} -
	<cjm-link type="internal" [to]="editLink" [title]="i18nKeys.Registration.CreateAsNew.EditLink.Title | translate">{{
		i18nKeys.Registration.CreateAsNew.EditLink.Text | translate
	}}</cjm-link>
</p>

<div class="c-create-as-new__content">
	<div class="c-create-as-new__recap u-margin-bottom-xl">
		@if (name) {
			<h4 class="c-vloket-header c-vloket-header--h4 u-no-margin-bottom">
				{{ name }}
			</h4>
		}

		@if (address) {
			<cjm-tag class="u-margin-bottom u-no-padding" icon="fa-location-dot" iconType="fa-solid">
				{{ address | oneLineAddress }}
			</cjm-tag>
		}

		@if (activities.length > 0) {
			<div class="u-margin-bottom-xl">
				<vloket-association-detail-activities [activities]="activities" />
			</div>
		}

		@if (representative) {
			<div>
				<b>{{ representative.representativeFirstnameField }} {{ representative.representativeSurnameField }}</b>
				<p>
					<fa-icon icon="fa-envelope" iconType="fa-regular" />
					{{ representative.representativeEmailField }}
				</p>
				<p>
					<fa-icon icon="fa-phone" />
					{{ representative.representativePhoneField }}
				</p>

				@if (amountOfInvites > 0) {
					<p>
						+
						@if (amountOfInvites === 1) {
							{{ amountOfInvites }}
							{{ i18nKeys.Registration.CreateAsNew.InvitesDisclaimer.Single | translate }}
						} @else {
							{{ amountOfInvites }}
							{{ i18nKeys.Registration.CreateAsNew.InvitesDisclaimer.Multiple | translate }}
						}
					</p>
				}
			</div>
		}
	</div>

	<div class="c-create-as-new__actions">
		<!-- Denis: Optional slot to add additional content like buttons -->
		<ng-content />
	</div>
</div>
