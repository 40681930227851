<cjm-layout-container
	class="p-registration-start"
	layoutType="full-width"
	asidePosition="right"
	[preventSpacing]="['top', 'bottom']"
>
	<ng-container main>
		<div class="p-registration-start__header">
			<h1 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.Start.Title | translate }}
				</span>
			</h1>

			<p>{{ i18nKeys.Registration.Start.Intro | translate }}</p>
			<p class="u-margin-bottom-xl">
				<fa-icon class="u-margin-right-sm" icon="fa-clock" iconType="fa-regular" />
				<span [outerHTML]="i18nKeys.Registration.Start.TimeNotice | translate"></span>
			</p>
		</div>

		<!-- Association -->
		<section class="p-registration-start__content">
			<div class="p-registration-start__content__card">
				<div class="p-registration-start__content__card__top">
					<h2 class="c-vloket-header c-vloket-header--h2">
						<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
							{{ i18nKeys.Registration.Start.Association.Title | translate }}
						</span>
					</h2>
					<div [innerHTML]="i18nKeys.Registration.Start.Association.BodyHTML | translate"></div>
				</div>
				<div class="p-registration-start__content__card__bottom">
					<vloket-icon-list class="u-margin-bottom-lg" [items]="associationCharacteristics$ | async" />

					<div class="p-registration-start__content__card__bottom__actions">
						<cjm-link
							*hasRole="'CIVILIAN'; else redirectFVRegistrationButton"
							[classes]="buttonClasses.LinkButtonFullWidth"
							[title]="i18nKeys.Registration.Start.Association.Button.Title | translate"
							[to]="['../../', appRoutePaths.Registration, appRoutePaths.RegistrationForm]"
						>
							{{ i18nKeys.Registration.Start.Association.Button.Text | translate }}
						</cjm-link>

						<cjm-link
							[to]="['../../', appRoutePaths.Advice, 'juridische_vorm_vereniging']"
							[title]="
								i18nKeys.Actions.MoreInformation.title
									| translate
										: {
												title:
													i18nKeys.Registration.Start.Association.MoreInfoLink.AccessibleTitle
													| translate
										  }
							"
						>
							{{ i18nKeys.Actions.MoreInformation.text | translate }}
						</cjm-link>
					</div>
				</div>
			</div>
			<!-- Organisation / Foundation -->
			<div class="p-registration-start__content__card">
				<div class="p-registration-start__content__card__top">
					<h2 class="c-vloket-header c-vloket-header--h2">
						<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
							{{ i18nKeys.Registration.Start.Foundation.Title | translate }}
						</span>
					</h2>
					<div [innerHTML]="i18nKeys.Registration.Start.Foundation.BodyHTML | translate"></div>
				</div>
				<div class="p-registration-start__content__card__bottom">
					<vloket-icon-list class="u-margin-bottom-lg" [items]="foundationCharacteristics$ | async" />

					<div class="p-registration-start__content__card__bottom__actions">
						<cjm-button
							cjmUserEIDIdentitySwitch
							[classes]="buttonClasses.LinkButtonFullWidth"
							[title]="i18nKeys.Registration.Start.Foundation.Button.Title | translate"
							[callback]="callbackUrl + acmTargetGroups.EA"
						>
							{{ i18nKeys.Registration.Start.Foundation.Button.Text | translate }}
						</cjm-button>

						<cjm-link
							[to]="['../../', appRoutePaths.Advice, 'juridische_vorm_vereniging']"
							[title]="
								i18nKeys.Actions.MoreInformation.title
									| translate
										: {
												title:
													i18nKeys.Registration.Start.Association.MoreInfoLink.AccessibleTitle
													| translate
										  }
							"
						>
							{{ i18nKeys.Actions.MoreInformation.text | translate }}
						</cjm-link>
					</div>
				</div>
			</div>
			<!--
			The divisions module is currently disabled because of third party changes that need to be implemented.

			~ Denis
			-->
			<!-- Department -->
			<!--			<div class="p-registration-start__content__card">-->
			<!--				<div class="p-registration-start__content__card__top">-->
			<!--					<h2 class="c-vloket-header c-vloket-header&#45;&#45;h2">-->
			<!--						<span class="c-vloket-text-marking c-vloket-text-marking&#45;&#45;line-through">-->
			<!--							{{ i18nKeys.Registration.Start.Department.Title | translate }}-->
			<!--						</span>-->
			<!--					</h2>-->
			<!--					<p class="u-margin-bottom-xl">-->
			<!--						<span [outerHTML]="i18nKeys.Registration.Start.Department.BodyHTML | translate"></span>-->
			<!--					</p>-->
			<!--					<cjm-button-->
			<!--						cjmUserEIDLogin-->
			<!--						[classes]="buttonClasses.LinkButtonFullWidth"-->
			<!--						[title]="i18nKeys.Registration.Start.Department.Button.Title | translate"-->
			<!--						[callback]="callbackUrlDivision"-->
			<!--					>-->
			<!--						{{ i18nKeys.Registration.Start.Department.Button.Text | translate }}-->
			<!--					</cjm-button>-->
			<!--				</div>-->
			<!--				<div class="p-registration-start__content__card__bottom">-->
			<!--					<a routerLink="#" [title]="i18nKeys.Registration.Start.Department.MoreInfoLink.Title | translate">-->
			<!--						{{ i18nKeys.Registration.Start.Department.MoreInfoLink.Text | translate }}-->
			<!--					</a>-->
			<!--				</div>-->
			<!--			</div>-->
			<!-- Unsure -->
			<!--			<div class="p-registration-start__content__card">-->
			<!--				<div class="p-registration-start__content__card__top">-->
			<!--					<h2 class="c-vloket-header c-vloket-header&#45;&#45;h2">-->
			<!--						<span class="c-vloket-text-marking c-vloket-text-marking&#45;&#45;line-through">-->
			<!--							{{ i18nKeys.Registration.Start.NotQuiteSure.Title | translate }}-->
			<!--						</span>-->
			<!--					</h2>-->
			<!--					<p class="u-margin-bottom-xl">-->
			<!--						<span [outerHTML]="i18nKeys.Registration.Start.NotQuiteSure.BodyHTML | translate"></span>-->
			<!--					</p>-->
			<!--				</div>-->
			<!--				<div class="p-registration-start__content__card__bottom">-->
			<!--					<a routerLink="#" [title]="i18nKeys.Registration.Start.NotQuiteSure.MoreInfoLink.Title | translate">-->
			<!--						{{ i18nKeys.Registration.Start.NotQuiteSure.MoreInfoLink.Text | translate }}-->
			<!--					</a>-->
			<!--				</div>-->
			<!--			</div>-->
		</section>
	</ng-container>
</cjm-layout-container>

<ng-template #redirectFVRegistrationButton>
	<cjm-button
		cjmUserEIDLogin
		[classes]="buttonClasses.LinkButtonFullWidth"
		[title]="i18nKeys.Registration.Start.Association.Button.Title | translate"
		[callback]="callbackUrl + acmTargetGroups.BUR"
		[capHint]="acmTargetGroups.BUR"
		[withWarning]="true"
		[warningLabels]="authRedirectFVRegistrationWarning"
	>
		{{ i18nKeys.Registration.Start.Association.Button.Text | translate }}
	</cjm-button>
</ng-template>
