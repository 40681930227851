import { JsonPipe } from '@angular/common';
import { Component, HostBinding, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { WithRouterLinkPipe } from '@studiohyperdrive/ngx-utils';
import { debounce } from 'lodash';
import { takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MetaService } from '@cjm/shared/core';
import { STOP_CASES, STOP_CODES } from '@cjm/shared/redirects';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { OnDestroyComponent } from '@cjm/shared/types';
import {
	AlertType,
	ButtonClasses,
	AlertComponent,
	ButtonComponent,
	FaIconComponent,
	InfoComponent,
	LayoutContainerComponent,
	LinkComponent
} from '@cjm/shared/ui/common';
import { IMainActivity } from '@cjm/v-loket/repositories';
import { AssociationCardComponent, AssociationCode, AssociationDetailEntity } from '@cjm/v-loket/shared';

import { DuplicateFacade } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { CreateAsNewComponent } from '../../components';
import { IRegistration } from '../../interfaces';

@Component({
	templateUrl: './duplicate-check.component.html',
	styleUrls: ['./duplicate-check.component.scss'],
	providers: [DuplicateFacade],
	standalone: true,
	imports: [
		LayoutContainerComponent,
		ButtonComponent,
		RouterLink,
		FaIconComponent,
		AlertComponent,
		AssociationCardComponent,
		LinkComponent,
		InfoComponent,
		TranslateModule,
		WithRouterLinkPipe,
		CreateAsNewComponent,
		JsonPipe
	]
})
export class DuplicateCheckPageComponent extends OnDestroyComponent implements OnInit {
	@HostBinding('class.p-registration-duplicates') private readonly hasRegistrationDuplicatesClass: boolean = true;

	public readonly conflictingAssociations: Signal<AssociationDetailEntity[]> = toSignal(
		this.duplicateFacade.conflictingAssociations$
	);
	public readonly registrationData: Signal<IRegistration> = toSignal(this.duplicateFacade.registrationData$);
	public readonly confirmationToken: Signal<string> = toSignal(this.duplicateFacade.confirmationToken$);
	public readonly actionInProgress: Signal<boolean> = toSignal(this.duplicateFacade.actionInProgress$);
	public readonly mainActivities: Signal<IMainActivity[]> = toSignal(this.duplicateFacade.getMainActivities());

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly alertType: typeof AlertType = AlertType;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly createAsNew = debounce<(registrationData: IRegistration, token: string) => void>(
		this.createNew.bind(this),
		600
	);
	public readonly associationCode: typeof AssociationCode = AssociationCode;
	public readonly stopCases: typeof STOP_CASES = STOP_CASES;
	public readonly stopCodes: typeof STOP_CODES = STOP_CODES;

	constructor(
		private readonly duplicateFacade: DuplicateFacade,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		public readonly i18nService: I18nService,
		private readonly metaService: MetaService
	) {
		super();
	}

	public ngOnInit(): void {
		this.metaService.updateMetaData(
			{
				title: this.i18nService.getTranslation(this.i18nKeys.Registration.DuplicateCheck.Title),
				description: this.i18nService.getTranslation(this.i18nKeys.Registration.DuplicateCheck.MetaDescription),
				pageUrl: this.router.url
			},
			// Abdurrahman: this page shouldn't be indexed
			false
		);
	}

	/**
	 * createNew
	 *
	 * The createNew method will confirm that the current registration is a new one.
	 */
	private createNew(registrationData: IRegistration, token: string): void {
		if (!registrationData || !token) {
			return;
		}

		this.duplicateFacade
			.submitRegistration(registrationData, token)
			.pipe(
				tap(() => {
					this.router.navigate(['..', this.appRoutePaths.RegistrationRedirectRegistration], {
						relativeTo: this.route
					});
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
